import QRCode from 'qrcode';
import {useCustomSnackbar} from './useCustomSnackbar';
import {jsPDF} from '../assets/fonts/upgradedJsPdf';
import gear from '../images/qrStickers/sticker-gear.png';
import car from '../images/qrStickers/sticker-car.png';
import box from '../images/qrStickers/sticker-box.png';
import packedBox from '../images/qrStickers/sticker-packed-box.png';
import bundle from '../images/qrStickers/main_bundle.png';
import {getImageDataUrlFromUrl} from '../helpers/global/functions/image';
import {getSelf} from '../api/User';
import moment from 'moment';
import {toBase64Json} from '../helpers/global/functions/convert';

const getBase64ContentFromDoc = doc => {
    const dataURI = doc.output('datauristring');
    return dataURI.split(',')[1];
};

/**
 * 'super long line' => ['super long', 'line']
 * @param {string} text
 * @param {number} lineWidth
 * @param {jsPDF} doc
 * @returns {string[]}
 */
function separateTextIntoLines(text, lineWidth, doc) {
    const words = text.split(' ');
    const lines = [];

    let line = '';

    while (words.length > 0) {
        const word = words.shift();

        if (word) {
            const newValue = line ? line + ' ' + word : word;
            const width = doc.getTextWidth(newValue);
            if (width > lineWidth) {
                lines.push(line);
                line = word;
            } else {
                line = newValue;
            }
        }
    }

    if (line) {
        lines.push(line);
    }

    return lines.filter(x => x);
}

/**
 * 'short text' => 'short text'
 * 'super long text' => 'super long...'
 * @param {string} text
 * @param {number} lineWidth
 * @param {jsPDF} doc
 * @returns {string}
 */
function dotsInTheEnd(text, lineWidth, doc) {
    if (doc.getTextWidth(text) <= lineWidth) {
        return text;
    }

    for (let newText = text; newText.length > 0; newText = newText.slice(0, -1)) {
        if (doc.getTextWidth(newText + '...') <= lineWidth) {
            return newText + '...';
        }
    }

    return '...';
}

/**
 *
 * @param {{id: number, name: string}[]} mountingSides
 * @returns {string}
 */
function parseMountingSide(mountingSides) {
    return [...(mountingSides || [])].reduce((acc, side) => acc + side.name[0].toUpperCase(), '');
}

export function useStickerGenerator() {
    const {showError} = useCustomSnackbar();

    /**
     *
     * @param {string} text
     * @param {number} x
     * @param {number} y
     * @param {number} size
     * @param {jsPDF} doc
     *
     */
    async function addQR(text, x, y, size, doc) {
        await QRCode.toDataURL(text).then(
            url => doc.addImage(url, 'PNG', x, y, size, size),
            error => showError(error)
        );
    }

    async function generateTextQRSticker(text, print = null) {
        const makeStickers = async (doc, text) => {
            const addTextQR = async () => {
                // Define sticker dimensions
                const rectSizeWidth = 56;
                const rectSizeHeight = 15;
                const qrSize = 13;
                const padding = 1;

                // Calculate positions
                const qrX = rectSizeWidth - qrSize - padding;
                const qrY = (rectSizeHeight - qrSize) / 2;

                // Add QR code on the right side
                await addQR(text, qrX, qrY, qrSize, doc).finally(() => {
                    // Calculate available text width
                    const availableTextWidth = qrX - (padding * 2);

                    let fontSize = 14;
                    doc.setFont('Oswald', 'normal');

                    function getTextLines(text, maxWidth) {
                        doc.setFontSize(fontSize);
                        let currentText = '';
                        let lines = [];

                        for (let char of text) {
                            let testText = currentText + char;
                            if (doc.getTextWidth(testText) <= maxWidth) {
                                currentText = testText;
                            } else {
                                lines.push(currentText);
                                currentText = char;
                            }
                        }
                        if (currentText) {
                            lines.push(currentText);
                        }
                        return lines;
                    }

                    // Try to fit text by reducing font size if needed
                    let lines = getTextLines(text, availableTextWidth);
                    let lineHeight = fontSize * 0.3;
                    let totalHeight = (lines.length * lineHeight);

                    // Reduce font size until text fits vertically
                    while (totalHeight > rectSizeHeight - padding * 2 && fontSize > 6) {
                        fontSize--;
                        lines = getTextLines(text, availableTextWidth);
                        lineHeight = fontSize * 0.3;
                        totalHeight = (lines.length * lineHeight);
                    }

                    const startY = (rectSizeHeight - totalHeight) / 2 + lineHeight;

                    lines.forEach((line, index) => {
                        const y = startY + (index * lineHeight);
                        doc.text(line, padding * 2, y);
                    });

                    // Add rounded border
                    doc.roundedRect(0.1, 0.1, rectSizeWidth, rectSizeHeight, 2, 2);

                    // Open the print-Dialog when opened in a PDF-viewer
                    doc.autoPrint({variant: 'non-conform'});
                });
            };

            await addTextQR();
            return doc;
        };

        let doc = new jsPDF({orientation: 'landscape', format: [15.2, 56.2]});
        await makeStickers(doc, text);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generateLargeTextQRSticker(text, print = null) {
        const makeStickers = async (doc, text) => {
            const addTextQR = async () => {
                // Define sticker dimensions
                const rectSizeWidth = 94;
                const rectSizeHeight = 56;
                const qrSize = 45;
                const padding = 2;

                // Calculate positions - QR on right side
                const qrX = rectSizeWidth - qrSize - padding;
                const qrY = (rectSizeHeight - qrSize) / 2;

                // Add QR code on the right side
                await addQR(text, qrX, qrY, qrSize, doc).finally(() => {
                    // Calculate available text width
                    const availableTextWidth = qrX - (padding * 3);

                    let fontSize = 48;
                    doc.setFont('Oswald', 'normal');

                    function getTextLines(text, maxWidth) {
                        doc.setFontSize(fontSize);
                        let currentText = '';
                        let lines = [];

                        for (let char of text) {
                            let testText = currentText + char;
                            if (doc.getTextWidth(testText) <= maxWidth) {
                                currentText = testText;
                            } else {
                                lines.push(currentText);
                                currentText = char;
                            }
                        }
                        if (currentText) {
                            lines.push(currentText);
                        }
                        return lines;
                    }

                    // Try to fit text by reducing font size if needed
                    let lines = getTextLines(text, availableTextWidth);
                    let lineHeight = fontSize * 0.3;
                    let totalHeight = (lines.length * lineHeight);

                    // Reduce font size until text fits vertically
                    while ((totalHeight > rectSizeHeight - padding * 4) && fontSize > 8) {
                        fontSize = fontSize > 24 ? fontSize - 6 : fontSize - 2;
                        doc.setFontSize(fontSize);
                        lines = getTextLines(text, availableTextWidth);
                        lineHeight = fontSize * 0.3;
                        totalHeight = (lines.length * lineHeight);
                    }

                    const startY = (rectSizeHeight - totalHeight) / 2 + lineHeight;

                    lines.forEach((line, index) => {
                        const y = startY + (index * lineHeight);
                        doc.text(line, padding * 3, y);
                    });

                    // Using exact border positioning from generatePartStickers
                    doc.roundedRect(1, 1, 94, 56, 2, 2);

                    // Open the print-Dialog when opened in a PDF-viewer
                    doc.autoPrint({variant: 'non-conform'});
                });
            };

            await addTextQR();
            return doc;
        };

        let doc = new jsPDF({
            orientation: 'landscape',
            format: [58, 96],
            unit: 'mm'
        });
        await makeStickers(doc, text);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generateOrderPackedStickers(orders, print = null) {
        const makeStickers = async (doc, orders) => {
            const addOrderPackedQR = async order => {
                const rectTransform = {x: 55.75, y: 27.75};
                const padding = {x: 2, y: 2};
                const orderCenterWithTextYOffset = 1;
                const bottomLineYValue = rectTransform.y - padding.y;
                const rightSizeXValue = rectTransform.x - padding.x;

                const qrTransform = {x: 36, y: 4, size: 20};
                const qrData = `package:${toBase64Json({
                    id: `shipment_number:${order.shipment.shipmentNumber}`,
                    warehouseId: order.shipment.warehouseId,
                })}`;
                await addQR(qrData, qrTransform.x, qrTransform.y, qrTransform.size, doc).finally(async () => {
                    // Add rounded border.
                    doc.roundedRect(0.1, 0.1, rectTransform.x, rectTransform.y, padding.x, padding.y);

                    // Add user data.
                    doc.setFontSize(5);
                    doc.setFont('Oswald', 'normal');
                    const userResponse = await getSelf();
                    const userLineText = `${userResponse.data.firstName} ${moment().format('YYYY MM DD HH:mm:ss')}`;
                    const textDimensions = doc.getTextDimensions(userLineText);
                    const userLineTransform = {
                        x: rightSizeXValue - textDimensions.w,
                        y: bottomLineYValue
                    };
                    doc.text(userLineTransform.x, userLineTransform.y, userLineText, null, 0);

                    // Add part image at the bottom line.
                    const boxImageTransform = {x: padding.x, size: 8};
                    boxImageTransform.y = bottomLineYValue - boxImageTransform.size + orderCenterWithTextYOffset;

                    doc.addImage(
                        packedBox,
                        'PNG',
                        boxImageTransform.x,
                        boxImageTransform.y, // Subtract from image size to take bottom corner.
                        boxImageTransform.size,
                        boxImageTransform.size,
                        undefined,
                        undefined,
                        0
                    );
                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(16);

                    // Add part id at the bottom line.
                    const partIdXOffset = boxImageTransform.size + padding.x;
                    doc.text(padding.x + partIdXOffset, bottomLineYValue, `${order.shipment.shipmentNumber}`, null, 0);

                    const marketplaceLogoTransform = {x: padding.x, size: 5};
                    marketplaceLogoTransform.y = qrTransform.y; //  - marketplaceLogoTransform.size + orderCenterWithTextYOffset;

                    if (order.orderMedia.marketplaceLogoLink) {
                        const imageData = await getImageDataUrlFromUrl(order.orderMedia.marketplaceLogoLink);
                        doc.addImage(
                            imageData,
                            'PNG',
                            marketplaceLogoTransform.x,
                            marketplaceLogoTransform.y,
                            marketplaceLogoTransform.size,
                            marketplaceLogoTransform.size,
                            undefined,
                            undefined,
                            0
                        );
                    }

                    if (order.order.sellingNumber) {
                        const sellingNumberXImageOffset = order.orderMedia.marketplaceLogoLink
                            ? marketplaceLogoTransform.size + padding.x
                            : 0;
                        const maxLengthPerLine = 18; // Maximum number of characters per line
                        const sellingNumberText = `${order.order.sellingNumber}`;

                        doc.setFontSize(9);
                        doc.setFont('Oswald', 'normal');

                        // Split the selling number into multiple lines
                        const lines = [];
                        for (let i = 0; i < sellingNumberText.length; i += maxLengthPerLine) {
                            lines.push(sellingNumberText.substring(i, i + maxLengthPerLine));
                        }

                        // Determine the initial position
                        const initialY = qrTransform.y;
                        let currentY = initialY;

                        // Print each line
                        lines.forEach((line) => {
                            const lineDimensions = doc.getTextDimensions(line);
                            const lineTransform = {
                                x: padding.x + sellingNumberXImageOffset,
                                y: currentY + lineDimensions.h,
                            };
                            doc.text(lineTransform.x, lineTransform.y, line, null, 0);
                            currentY += lineDimensions.h; // Move Y position for the next line
                        });
                    }

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});
                });
            };

            await addOrderPackedQR(orders[0]);
            orders.shift();
            for (const order of orders) {
                doc.addPage();
                await addOrderPackedQR(order);
            }
            return doc;
        };
        let doc = new jsPDF({orientation: 'landscape', format: [28, 56]});
        await makeStickers(doc, orders);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generatePartSmallStickers(parts, print = null) {
        const makeStickers = async (doc, parts) => {
            const addSmallPartQR = async part => {
                await addQR(`${window.location.origin}/parts?ids=${part.id}`, 41, 0, 15, doc).finally(async () => {
                    // Add rounded border.
                    const rectSizeWidth = 56;
                    const rectSizeHeight = 15;
                    doc.roundedRect(0.1, 0.1, rectSizeWidth, rectSizeHeight, 2, 2);

                    const bottomLineYValue = rectSizeHeight - 2;
                    const LeftPadding = 2;

                    // Add part image at the bottom line.
                    const partImageSize = 6.5;
                    const partCenterWithTextYOffset = 1;
                    doc.addImage(
                        gear,
                        'PNG',
                        LeftPadding,
                        bottomLineYValue - partImageSize + partCenterWithTextYOffset, // Subtract from image size to take bottom corner.
                        partImageSize,
                        partImageSize,
                        undefined,
                        undefined,
                        0
                    );

                    doc.setFontSize(10);
                    doc.setFont('Oswald', 'light');

                    // Add vehicle make and model text.
                    separateTextIntoLines(
                        [part?.vehicle?.kType?.make, part?.vehicle?.kType?.model].filter(x => x).join(' '),
                        38,
                        doc
                    ).forEach((line, index) => {
                        doc.text(LeftPadding, 4 * ++index, line, null, 0);
                    });

                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(16);

                    // Add part id at the bottom line.
                    const partIdXOffset = 1;
                    doc.text(LeftPadding + partImageSize + partIdXOffset, bottomLineYValue, `${part.id}`, null, 0);

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});
                });
            };

            await addSmallPartQR(parts[0]);
            parts.shift();
            for (const part of parts) {
                doc.addPage();
                await addSmallPartQR(part);
            }
            return doc;
        };
        let doc = new jsPDF({orientation: 'landscape', format: [15.2, 56.2]});
        await makeStickers(doc, parts);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generatePartStickers(parts, print = null) {
        /**
         *
         * @param {jsPDF} doc
         * @param {any[]} parts
         */
        const makeStickers = async (doc, parts) => {
            let first = true;
            for (const part of parts) {
                if (first) {
                    first = false;
                } else {
                    doc.addPage();
                }
                await addQR(`${window.location.origin}/parts?ids=${part.id}`, 1, 41, 15, doc).finally(async () => {
                    // Add rounded border.
                    doc.roundedRect(1, 1, 94, 56, 2, 2);

                    // Set font family and size.
                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(14.25);

                    const lineWidth = 67;
                    // Add exact part category name.
                    if (part.exactPartCategoryName) {
                        const lines = separateTextIntoLines(part.exactPartCategoryName, lineWidth, doc);
                        lines.forEach((line, index) => {
                            doc.text(line, 18, 8 + index * 5);
                        });
                    }
                    // Add line to separate exact part category name and part's vehicle details.
                    doc.line(18, 14.6, 18 + lineWidth, 14.6);

                    if (part.vehicle) {
                        doc.setFont('Oswald', 'normal');
                        // Add part's vehicle manufacture year, make and model.
                        doc.text(
                            dotsInTheEnd(
                                [part.vehicle.manufactureYear, part.vehicle.kType.make, part.vehicle.kType.model]
                                    .filter(x => x)
                                    .join(' '),
                                lineWidth,
                                doc
                            ),
                            18,
                            20
                        );
                        // Add part's vehicle engine capacity, engine power kw and engine power hp.
                        doc.text(
                            dotsInTheEnd(
                                `${(part.vehicle.kType.engineCapacity && part.vehicle.kType.engineCapacity + ' ') || ''}${
                                    (part.vehicle.kType.enginePowerKw && part.vehicle.kType.enginePowerKw + ' KW ') || ''
                                }${(part.vehicle.kType.enginePowerHp && '(' + part.vehicle.kType.enginePowerHp + ' HP)') || ''}`,
                                lineWidth,
                                doc
                            ),
                            18,
                            26
                        );
                        // Add part's vehicle type, body type and gearbox.
                        doc.text(
                            dotsInTheEnd(
                                [part.vehicle.kType.type, part.vehicle.kType.bodyType, part.vehicle.gearbox.name]
                                    .filter(x => x)
                                    .join(' '),
                                lineWidth,
                                doc
                            ),
                            18,
                            32
                        );

                        // Add part's vehicle make and model and rotate the text.
                        doc.setFontSize(10);
                        doc.setFont('Oswald', 'light');
                        separateTextIntoLines(
                            [part.vehicle.kType.make, part.vehicle.kType.model].filter(x => x).join(' '),
                            38,
                            doc
                        ).forEach((line, index) => {
                            doc.text(12 - 3.5 * index, 4, line, null, 270);
                        });
                    }

                    // Add MPN - Manufacturer part number.
                    if (part.manufacturerPartNumber) {
                        doc.setFont('Oswald', 'bold');
                        doc.setFontSize(14.25);
                        doc.text(dotsInTheEnd(part.manufacturerPartNumber, lineWidth, doc), 18, 40);
                    }

                    // Add part references.
                    if (part.references.length > 0) {
                        doc.setFont('Oswald', 'normal');
                        doc.setFontSize(8);
                        doc.text(
                            dotsInTheEnd(part.references.map(reference => reference.description).join(', '), lineWidth, doc),
                            18,
                            44
                        );
                    }

                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(16);
                    // Add rotated part id at the top left corner under icon.
                    doc.text(3, 10, `${part.id}`, null, 270);
                    // Add part id at the bottom right corner.
                    doc.text(93 - doc.getTextWidth(`${part.id}`), 54, `${part.id}`);

                    // Add rotated image to top left corner.
                    doc.addImage(gear, 'PNG', 2, -3, 6.5, 6.5, undefined, undefined, 270);

                    // Add image to bottom right corner.
                    doc.addImage(gear, 'PNG', 87 - doc.getTextWidth(`${part.id}`), 48.5, 6.5, 6.5);

                    // Add part's vehicle condition letter.
                    if (part.conditionLetter) {
                        const partIdWidth = doc.getTextWidth(`${part.id}`);
                        doc.setFontSize(9.3);
                        doc.setFont('Oswald', 'normal');
                        doc.circle(84.4 - partIdWidth, 52, 2.3);
                        doc.text(83.6 - partIdWidth, 53.3, part.conditionLetter);
                    }

                    doc.setFontSize(9);
                    doc.setFont('Oswald', 'light');

                    // Add part exact part category id.
                    if (part.exactPartCategoryId) {
                        doc.text(89.1, 5, `${part.exactPartCategoryId}`, null, 270);
                        doc.roundedRect(88, 4, 5, 2 + doc.getTextWidth(`${part.exactPartCategoryId}`), 1, 1);
                    }

                    // Add part mounting sides.
                    const ms = parseMountingSide(part.mountingSides);
                    if (ms) {
                        if (ms.length === 2) {
                            doc.text(89.2, 46.7, ms);
                            doc.roundedRect(88, 43, 5, 5, 1, 1);
                        } else if (ms.length === 1) {
                            doc.text(89.8, 46.7, ms);
                            doc.roundedRect(88, 43, 5, 5, 1, 1);
                        } else {
                            doc.text(91.2 - doc.getTextWidth(ms), 46.7, ms);
                            doc.roundedRect(89.6 - doc.getTextWidth(ms), 43, 3 + doc.getTextWidth(ms), 5, 1, 1);
                        }
                    }

                    doc.setLineDashPattern([0.5, 0.5], 0);
                    // Add part's vehicle id.
                    if (part.vehicle && part.vehicle.id) {
                        doc.text(89.1, 17, `${part.vehicle.id}`, null, 270);
                        doc.roundedRect(88, 15, 5, 4 + doc.getTextWidth(`${part.vehicle.id}`), 1, 1);
                    }

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});

                    // Add vertical dashed line in the left.
                    doc.line(16, 4, 16, 55);
                });
            }
        };

        const doc = new jsPDF({orientation: 'landscape', format: [96, 58]});

        await makeStickers(doc, parts);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generateVehicleSmallStickers(vehicles, isVehicleAsCollection = false, print = null) {
        const makeStickers = async (doc, vehicles) => {
            const addSmallVehicleQR = async vehicle => {
                await addQR(
                    `${window.location.origin}/${isVehicleAsCollection ? 'collections' : 'vehicles'}/${vehicle.id}`,
                    41,
                    0,
                    15,
                    doc
                ).finally(async () => {
                    // Add rounded border.
                    const rectSizeWidth = 56;
                    const rectSizeHeight = 15;
                    doc.roundedRect(0.1, 0.1, rectSizeWidth, rectSizeHeight, 2, 2);

                    const bottomLineYValue = rectSizeHeight - 2;
                    const LeftPadding = 2;

                    // Add vehicle image at the bottom line.
                    const vehicleImageSize = 6.5;
                    const vehicleCenterWithTextYOffset = 1;
                    doc.addImage(
                        isVehicleAsCollection ? bundle : car,
                        'PNG',
                        LeftPadding,
                        bottomLineYValue - vehicleImageSize + vehicleCenterWithTextYOffset, // Subtract from image size to take bottom corner.
                        vehicleImageSize,
                        vehicleImageSize,
                        undefined,
                        undefined,
                        0
                    );

                    doc.setFontSize(10);
                    doc.setFont('Oswald', 'light');

                    // Add vehicle make and model text.
                    separateTextIntoLines(
                        [vehicle?.kType?.make, vehicle?.kType?.model].filter(x => x).join(' '),
                        38,
                        doc
                    ).forEach((line, index) => {
                        doc.text(LeftPadding, 4 * ++index, line, null, 0);
                    });

                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(16);

                    // Add vehicle id at the bottom line.
                    const vehicleIdXOffset = 1;
                    doc.text(LeftPadding + vehicleImageSize + vehicleIdXOffset, bottomLineYValue, `${vehicle.id}`, null, 0);

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});
                });
            };

            await addSmallVehicleQR(vehicles[0]);
            vehicles.shift();
            for (const vehicle of vehicles) {
                doc.addPage();
                await addSmallVehicleQR(vehicle);
            }
            return doc;
        };
        let doc = new jsPDF({orientation: 'landscape', format: [15.2, 56.2]});
        await makeStickers(doc, vehicles);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generateVehicleStickers(vehicles, isVehicleAsCollection = false, print = null) {
        const makeStickers = async (doc, vehicles) => {
            let first = true;
            for (const vehicle of vehicles) {
                if (first) {
                    first = false;
                } else {
                    doc.addPage();
                }
                await addQR(
                    `${window.location.origin}/${isVehicleAsCollection ? 'collections' : 'vehicles'}/${vehicle.id}`,
                    1,
                    41,
                    15,
                    doc
                ).finally(async () => {
                    // Add rounded border.
                    doc.roundedRect(1, 1, 94, 56, 2, 2);

                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(14.25);

                    // Add rotated image to top left corner.
                    doc.addImage(isVehicleAsCollection ? bundle : car, 'PNG', 2, -3, 6.5, 6.5, undefined, undefined, 270);
                    const lineWidth = 71;

                    // Add vehicle year, make, model and type.
                    const lines = separateTextIntoLines(
                        [vehicle?.manufactureYear, vehicle?.kType?.make, vehicle?.kType?.model, vehicle?.kType?.type]
                            .filter(x => x)
                            .join(' '),
                        lineWidth,
                        doc
                    );
                    lines.forEach((line, index) => {
                        doc.text(line, 18, 8 + index * 5);
                    });

                    // Add line to separate vehicle year, make, model, type and vehicle other details.
                    doc.line(18, 14.6, 91, 14.6);

                    doc.setFont('Oswald', 'normal');

                    // Add vehicle engine capacity, engine power kw, engine power hp.
                    doc.text(
                        dotsInTheEnd(
                            `${(vehicle?.kType?.engineCapacity && vehicle?.kType?.engineCapacity + ' ') || ''}${
                                (vehicle?.kType?.enginePowerKw && vehicle?.kType?.enginePowerKw + ' KW ') || ''
                            }${(vehicle?.kType?.enginePowerHp && '(' + vehicle?.kType?.enginePowerHp + ' HP)') || ''}`,
                            lineWidth,
                            doc
                        ),
                        18,
                        20
                    );

                    // Add vehicle fuel type.
                    doc.text(
                        dotsInTheEnd(
                            ((vehicle?.kType?.fuelType && `${vehicle?.kType?.fuelType} `) || '') +
                            ((vehicle?.emissionStandard?.name && `(${vehicle?.emissionStandard?.name})`) || ''),
                            lineWidth,
                            doc
                        ),
                        18,
                        26
                    );
                    // Add vehicle gearbox.
                    doc.text(
                        dotsInTheEnd(
                            ((vehicle?.gearbox?.name && `${vehicle?.gearbox?.name} `) || '') +
                            ((vehicle?.numberOfGears && `(${vehicle?.numberOfGears})`) || ''),
                            lineWidth,
                            doc
                        ),
                        18,
                        32
                    );

                    // Add vehicle body type.
                    doc.text(
                        dotsInTheEnd(
                            ((vehicle?.kType?.bodyType && `${vehicle?.kType?.bodyType} `) || '') +
                            ((vehicle?.numberOfDoors && `(${vehicle?.numberOfDoors})`) || ''),
                            lineWidth,
                            doc
                        ),
                        18,
                        38
                    );

                    // Add vehicle color name.
                    doc.text(
                        dotsInTheEnd(
                            ((vehicle?.color?.name && `${vehicle?.color?.name} `) || '') +
                            ((vehicle?.colorCode && `(${vehicle?.colorCode})`) || ''),
                            lineWidth,
                            doc
                        ),
                        18,
                        44
                    );

                    // Add engine code.
                    doc.text(
                        dotsInTheEnd(
                            `${(vehicle?.engineCode && vehicle?.engineCode) || ''}`,
                            lineWidth - (vehicle?.conditionLetter ? 22 : 15),
                            doc
                        ),
                        18,
                        50
                    );

                    doc.setFontSize(10);
                    doc.setFont('Oswald', 'light');

                    // Add vehicle make and model and rotate the text.
                    separateTextIntoLines(
                        [vehicle?.kType?.make, vehicle?.kType?.model].filter(x => x).join(' '),
                        38,
                        doc
                    ).forEach((line, index) => {
                        doc.text(12 - 3.5 * index, 4, line, null, 270);
                    });

                    doc.setFont('Oswald', 'bold');
                    doc.setFontSize(16);
                    // Add rotated vehicle id at the top left corner under icon.
                    doc.text(3, 10, `${vehicle.id}`, null, 270);
                    // Add vehicle id at the bottom right corner under icon.
                    doc.text(91.5 - doc.getTextWidth(`${vehicle.id}`), 54, `${vehicle.id}`);

                    // Add image to bottom right corner.
                    doc.addImage(
                        isVehicleAsCollection ? bundle : car,
                        'PNG',
                        85 - doc.getTextWidth(`${vehicle.id}`),
                        48.5,
                        6.5,
                        6.5
                    );

                    // Add vehicle condition letter.
                    if (vehicle?.conditionLetter) {
                        const vehicleIdWidth = doc.getTextWidth(`${vehicle.id}`);
                        doc.setFontSize(9.3);
                        doc.setFont('Oswald', 'normal');
                        doc.circle(82.2 - vehicleIdWidth, 52, 2.3);
                        doc.text(81.4 - vehicleIdWidth, 53.3, vehicle.conditionLetter);
                    }

                    // Add vertical dashed line in the left.
                    doc.setLineDashPattern([0.5, 0.5], 0);
                    doc.line(16, 4, 16, 55);

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});
                });
            }

            return doc;
        };

        let doc = new jsPDF({orientation: 'landscape', format: [96, 58]});
        await makeStickers(doc, vehicles);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    async function generateBinStickers(bins, print = null) {
        const makeStickers = async (doc, bins) => {
            let first = true;
            for (const bin of bins) {
                if (first) {
                    first = false;
                } else {
                    doc.addPage();
                }
                await addQR(`${window.location.origin}/parts?binIds=${bin.id}`, 30, 11.6, 35, doc).finally(async () => {
                    // Add rounded border.
                    doc.roundedRect(1, 1, 94, 56, 2, 2);

                    // Add rotated image to top left corner.
                    doc.addImage(box, 'PNG', 2.6, -3, 5.5, 5.5, undefined, undefined, 270);

                    // Set font family and size.
                    doc.setFont('Oswald', 'normal');
                    doc.setFontSize(14);

                    const verticalLineWidth = 44;

                    doc.text(8, 9, `${bin.id || ''}`, null, 270);

                    // Add bin name.
                    doc.text(3, 9, dotsInTheEnd(bin.box?.name || '', verticalLineWidth, doc), null, 270);

                    // Add storing place name.
                    if (doc.getTextWidth(bin.storingPlace?.name || '') + doc.getTextWidth(bin.box?.name || '') < 44) {
                        doc.text(3, 55 - doc.getTextWidth(bin.storingPlace?.name || ''), bin.storingPlace?.name || '', null, 270);
                    }

                    // Set font size.
                    doc.setFontSize(18);

                    const horizontalLineWidthWithIcon = 72;

                    // Add image to bottom right corner.
                    doc.addImage(
                        box,
                        'PNG',
                        86 - doc.getTextWidth(dotsInTheEnd(bin.box?.name || '', horizontalLineWidthWithIcon, doc)),
                        49.4,
                        6.3,
                        6.3
                    );

                    // Add bin name.
                    doc.text(
                        93 - doc.getTextWidth(dotsInTheEnd(bin.box?.name || '', horizontalLineWidthWithIcon, doc)),
                        55,
                        dotsInTheEnd(bin.box?.name || '', horizontalLineWidthWithIcon, doc)
                    );
                    doc.text(93 - doc.getTextWidth(`${bin.id || ''}`), 49, `${bin.id || ''}`);
                    const horizontalLineWidth = 83;

                    // Add storing place name.
                    doc.text(
                        93 - doc.getTextWidth(dotsInTheEnd(bin.storingPlace?.name || '', horizontalLineWidth, doc)),
                        8,
                        dotsInTheEnd(bin.storingPlace?.name || '', horizontalLineWidth, doc)
                    );

                    // Open the print-Dialog when opened in a PDF-viewer.
                    doc.autoPrint({variant: 'non-conform'});
                });
            }

            return doc;
        };

        let doc = new jsPDF({orientation: 'landscape', format: [96, 58]});
        doc = await makeStickers(doc, bins);

        if (print) {
            const base64 = getBase64ContentFromDoc(doc);
            await print(base64);
            return;
        }

        const blobUri = doc.output('bloburi');
        const screenWidthCenter = window.screen.width / 2 - 300;
        const screenHeightCenter = window.screen.height / 2 - 400;
        window.open(
            blobUri,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
        );
    }

    return {
        generatePartStickers,
        generatePartSmallStickers,
        generateOrderPackedStickers,
        generateVehicleStickers,
        generateVehicleSmallStickers,
        generateBinStickers,
        generateTextQRSticker,
        generateLargeTextQRSticker
    };
}
