import axios from './authRequest';

export const GetListsStatus = () => axios.get('/api/lists/status');
export const UpdateListsStatus = newStatus => axios.put('/api/lists/status', newStatus);

export const GetVehicleIdsByListId = id => axios.get(`/api/lists/${id}/vehicle-ids`);
export const UpdateListsTitle = listsTitle => axios.patch('/api/lists/title', listsTitle);

export const upsertList = list => axios.post('/api/lists', list);

export const getPartsByListIds = listIds => axios.get(`/api/lists/parts-data?listsIds=${listIds.join(',')}`);
export const getPartsFromAllLists = urlParams => axios.get('/api/lists/parts-by-lists', {params: urlParams});
export const getVehicles = base64 => axios.get('/api/lists/vehicles', {params: {base64: base64}});
export const getParts = base64 => axios.get('/api/lists/parts', {params: {base64: base64}});
export const getPartsDb = base64 => axios.get('/api/lists/parts/db', {params: {base64: base64}});
export const getOrders = base64 => axios.get('/api/lists/orders', {params: {base64: base64}});
export const getListsByProductIds = listIds => axios.post('/api/lists/for-list/by-product-ids', listIds);
export const getListsPdf = base64 => axios.get('/api/lists/pdf', {params: {base64: base64}});
export const getAllListsPdf = base64 => axios.get('/api/lists/all-pdf', {params: {base64: base64}});
export const getLists = base64 => axios.get('/api/lists', {params: {base64: base64}});
export const getListsDb = base64 => axios.get('/api/lists/db', {params: {base64: base64}});
export const getAllMinimalLists = () => axios.get('/api/lists/all-minimal');

export const moveParts = partsMoveToList => axios.post('/api/lists/move-parts', partsMoveToList);
export const getBins = base64 => axios.get('/api/lists/boxes', {params: {base64: base64}});
export const getBinsToBigForLink = filters => axios.post('/api/lists/boxes', filters);
export const deleteLists = listIds => axios.delete('/api/lists', {data: listIds});

export const getPartFilterOptionsWithPriorities = () => axios.get('/api/lists/parts/filters-options-and-priorities');
export const getPublicPartFilterOptionsWithPriorities = () =>
    axios.get('/api/public/part/filters-options-and-priorities');
export const getVehicleFilterOptionsWithPriorities = () =>
    axios.get('/api/lists/vehicles/filters-options-and-priorities');
export const getPartFilters = base64 => axios.get('/api/lists/parts/filters', {params: {base64: base64}});
export const getPublicPartFilters = base64 => axios.get('/api/public/part/filters', {params: {base64: base64}});
export const getVehicleFilters = base64 => axios.get('/api/lists/vehicles/filters', {params: {base64: base64}});
export const getCollectionFilters = base64 => axios.get('/api/lists/collections/filters', {params: {base64: base64}});
export const addPartsTranslations = base64 =>
    axios.get('/api/lists/parts/filters-add-translations', {params: {base64: base64}});
export const addPublicPartsTranslations = base64 =>
    axios.get('/api/public/part/filters-add-translations', {params: {base64: base64}});
export const addVehiclesTranslations = base64 =>
    axios.get('/api/lists/vehicles/filters-add-translations', {params: {base64: base64}});
